<template>
  <v-autocomplete
    v-if="visible"
    v-model="value"
    v-model:search="search"
    :label="label"
    :error-messages="meta.dirty || meta.touched ? errors : []"
    :items="safeItems"
    :item-value="itemValue"
    :item-title="itemTitle"
    clearable
    return-object
  >
    <template #append-inner>
      <mtf-field-icon
        :meta="meta"
        :has-errors="hasErrors"
      />
    </template>

    <!-- <template #chip="{ props, item }"> -->
    <!-- <v-chip
        v-bind="props"
        :prepend-avatar="item?.raw?.avatar"
        :prepend-icon="item?.raw?.icon"
        :text="item.title"
      /> -->
    <!-- </template> -->

    <template #item="{ props, item }">
      <v-list-item
        v-bind="props"
        :prepend-avatar="item?.raw?.avatar"
        :prepend-icon="item?.raw?.icon"
        :title="item.title"
        :subtitle="item?.raw?.subtitle"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useValues } from '@matterific/utils';
import { useMatterificField } from '#imports';
import MtfFieldIcon from '../FieldIcon.vue';

export default defineComponent({
  name: 'MtfFieldLookup',
  components: { MtfFieldIcon },
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    visible: { type: Boolean, default: true },
    // ----------------
    items: { type: [Array, Function, Object], required: true },
    itemRef: { type: String, default: '$ref' },
    itemValue: { type: String, default: 'id' },
    itemTitle: { type: String, default: 'title' }
  },
  //emits: ['update:modelValue', 'input',],
  // ----------------

  setup(props, context) {
    const { model, meta, value, errors, handleBlur, validate, hasErrors } = useMatterificField(
      props.name,
      props.schema,
      context
    ); // make sure we exclud eour current id from the list
    const safeItems = computed(() => useValues(props.items, [], model));

    return {
      meta,
      errors,
      handleBlur,
      validate,
      value,
      safeItems,
      hasErrors
    };
  },
  data() {
    return {
      search: ''
    };
  }
});
</script>
